import { isAuthTokenValid } from "../utils/rest_utils";

let btnSummarize: HTMLButtonElement;
let labelSummarize: HTMLLabelElement;
let btnWorkflows: HTMLButtonElement;
let labelWorkflows: HTMLLabelElement;
let btnAnswerEmail: HTMLButtonElement;
let labelAnswerEmail: HTMLLabelElement;

const officeReadyPromise = new Promise<void>((resolve, reject) => {
  Office.onReady((info) => {
    if (info.host === Office.HostType.Outlook) {
      resolve();
    } else {
      reject(new Error("Host is not Outlook"));
    }
  });
});

document.onreadystatechange = async () => {
  if (document.readyState === "complete") {
    await officeReadyPromise;
    await initialize();

    if (!(await isAuthTokenValid())) {
      logout();
    }
  }
};

window.addEventListener("beforeunload", () => {
  localStorage.setItem("previousPage", "menu.html");
});

async function initialize() {
  console.log("Initializing menu view...");

  document.getElementById("btn-logout").onclick = logout;

  btnSummarize = document.getElementById("btn-summarize-email") as HTMLButtonElement;
  btnSummarize.onclick = () => {
    document.location.href = "summarize_email.html";
  };
  labelSummarize = document.getElementById("label-summarize-email") as HTMLLabelElement;
  labelSummarize.innerText = "Hier können Sie eine Zusammenfassung des Textes der ausgewählten E-Mail erstellen lassen";

  btnWorkflows = document.getElementById("btn-workflows") as HTMLButtonElement;
  btnWorkflows.onclick = () => {
    document.location.href = "workflows.html";
  };
  labelWorkflows = document.getElementById("label-workflows") as HTMLLabelElement;
  labelWorkflows.innerText = "Hier können Sie automatisierte Arbeitsabläufe für den Text der E-Mail starten";

  btnAnswerEmail = document.getElementById("btn-answer-email") as HTMLButtonElement;
  btnAnswerEmail.onclick = () => {
    document.location.href = "answer_email.html";
  };
  labelAnswerEmail = document.getElementById("label-answer-email") as HTMLLabelElement;
  labelAnswerEmail.innerText = "Hier können Sie eine Antwort auf die E-Mail generieren lassen";
}

/**
 * This function logs out the user and redirects to the login page.
 *
 * @returns {void}
 */
function logout(): void {
  localStorage.removeItem("authToken");
  window.location.href = "login.html";
}
