import axios, { AxiosResponse } from "axios";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import * as ce from "./custom_errors";

/* eslint-disable-next-line no-undef */
const apiUrl = process.env.API_URL;
/* eslint-disable-next-line no-undef */
const llmUrl = process.env.LLM_URL;

export class Models {
  private static instance: Models;
  private llmDefaultModel: string = "phi3:14b-medium-128k-instruct-q6_K"; // default model
  private llmModels: string[] = [];
  private llmModel: string = "";
  private llmEmbedModelName = "multilingual-e5-large";

  private constructor() {}

  public static getInstance(): Models {
    if (!Models.instance) {
      Models.instance = new Models();
    }
    return Models.instance;
  }

  public async loadModels(): Promise<void> {
    console.log("Loading models...");
    this.llmModels = await getLlmModels();
  }

  public getLlmModel(): string {
    if (this.llmModel === "") {
      if (this.llmModels.includes(this.llmDefaultModel)) {
        this.llmModel = this.llmDefaultModel;
      } else {
        this.llmModel = this.llmModels[0];
      }
    }
    return this.llmModel;
  }

  public getLlmEmbedModel(): string {
    return this.llmEmbedModelName;
  }
}

/**
 * This function logs in the user.
 *
 * @param username The jassper username
 * @param password The jassper password
 * @returns {Promise<void>} A promise that resolves when the login request is sent
 */
export async function login(username: string, password: string): Promise<void> {
  try {
    localStorage.removeItem("authToken");
    const response: AxiosResponse = await axios.post(`${apiUrl}/authenticate`, {
      username: username,
      password: password,
    });

    if (response.status === 200 || response.status === 201) {
      console.log("Login successful! Setting auth-token.");
      localStorage.setItem("authToken", response.data);
      return Promise.resolve();
    } else {
      console.error("Login failed! Status code: " + response.status);
      return Promise.reject(new Error("Login failed! Status code: " + response.status));
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return Promise.reject(error.response);
      } else if (error.request) {
        return Promise.reject(new Error("Error trying to fetch auth-token: No response received"));
      }
    }
    return Promise.reject(new Error("Unexpected error trying to fetch auth-token"));
  }
}

export async function isAuthTokenValid(): Promise<boolean> {
  try {
    console.log("Checking if auth token is valid.");
    const response: AxiosResponse = await axios.get(`${apiUrl}/user/me`);
    return 200 <= response.status && response.status <= 201;
  } catch (error) {
    console.log("Auth token is invalid.");
    return false;
  }
}

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      if (localStorage.getItem("authToken")) {
        console.log("Token expired. Logging out.");
        localStorage.removeItem("authToken");
        window.top.location.href = "login.html";
      }
    }
    return Promise.reject(error);
  }
);

/**
 * This function checks if the llm-server is running.
 *
 * @returns {Promise<boolean>} A promise that resolves to true if the server is running, false otherwise.
 *
 * @throws {JaasperServiceError} If the service is not available.
 */
export async function isLlmServerRunning(): Promise<boolean> {
  // CURRENTLY USING HETZNER SERVER - ALWAYS RUNNING
  return true;
  /*
  try {
    const response: AxiosResponse = await axios.get(`${apiUrl}/llm/state`);
    const serverStateList = response.data as [{ server: string; state: string }];
    const premiumServer = serverStateList.find(
      (server: { server: string; state: string }) => server.server === "premium"
    );
    return premiumServer.state !== "stopped";
  } catch (error) {
    console.error("Error trying to check llm-server state:", error);
    throw new ce.JaasperServiceError("Service is not available. Please try again later or contact support.");
  }
    */
}

/**
 * This function posts a new llm-case.
 *
 * @param caseTitle The title of the new case
 *
 * @returns {Promise<AxiosResponse>} A promise that resolves when the case is posted
 *
 * @throws {CaseCreationTimeoutError} If the request times out
 * @throws {CaseCreationError} If the request fails for any other reason
 */
export async function postLlmCase(caseTitle: string): Promise<AxiosResponse> {
  try {
    if (caseTitle === "" || caseTitle === undefined || caseTitle === null) {
      throw new ce.IndexCreationError("Document name and text are required.");
    }

    const response: AxiosResponse = await axios.post(
      `${apiUrl}/case`,
      {
        index_location: "hetzner",
        rag_type: "STATIC",
        state: "created",
        title: caseTitle,
        type: "llm",
      },
      {
        timeout: 15000,
      }
    );
    return response;
  } catch (error) {
    if (error.code === "ECONNABORTED") {
      console.error("Timeout trying to post new llm-case");
      throw new ce.CaseCreationTimeoutError("Timeout! Services are not responding. Please try again later.");
    } else {
      console.error("Error trying to post new llm-case:", error);
      throw new ce.CaseCreationError("Unexpected error! Please contact support.");
    }
  }
}

/**
 * This function indexes the document text content for a case.
 *
 * @param caseId The id of the case
 * @param documentName The name of the document
 * @param documentText The text content of the document
 *
 * @returns {Promise<AxiosResponse>} A promise that resolves when the document is indexed
 *
 * @throws {IndexCreationTimeoutError} If the request times out
 * @throws {IndexCreationError} If the request fails for any other reason
 */
export async function postIndex(
  caseId: string,
  documentName: string,
  documentText: string,
  model: string,
  embedModel: string
): Promise<AxiosResponse> {
  try {
    if (
      documentName === "" ||
      documentName === undefined ||
      documentName === null ||
      documentText === "" ||
      documentText === undefined ||
      documentText === null
    ) {
      throw new ce.IndexCreationError("Document name and text are required.");
    }

    const response: AxiosResponse = await axios.post(
      `${llmUrl}/v1/index/text`,
      {
        case_id: caseId,
        index_id: caseId,
        text: documentText,
        name: documentName,
        model: model,
        embed_model_name: embedModel,
        // doc_id: string - NOT USED
        index_type: "vectore_store",
        chunk_size: 256,
        chunk_overlap: 20,
      },
      {
        timeout: 15000,
      }
    );
    return response;
  } catch (error) {
    if (error.code === "ECONNABORTED") {
      console.error("Timeout trying to post index text");
      throw new ce.IndexCreationTimeoutError("Timeout! Services are not responding. Please try again later.");
    } else {
      console.error("Error trying to post index text:", error);
      throw new ce.IndexCreationError("Unexpected error! Please contact support.");
    }
  }
}

export interface LlmCase {
  messages: LlmCaseMessage[];
}
export interface IMessageBase {
  content: string;
  temperature?: number;
  topic?: string;
  system_prompt?: string;
}
export interface IMessageMeta extends IMessageBase {
  model: string;
  timestamp: number;
}
export interface LlmCaseMessage extends IMessageBase {
  timestamp: number;
  role: LLMStreamEventType;
  model: string;
  topic: string;
  temperature: number;
  case_id: string;
  index_id: string;
  index_references: IndexReference[];
  system_prompt: string;
}
export interface IndexReference {
  id: string;
  score: number;
}
/**
 * This function retrieves a llm-case.
 *
 * @param caseId The id of the case
 *
 * @returns {Promise<JSON>} A promise with the case data as JSON
 */
export async function getLlmCase(caseId: string): Promise<LlmCase> {
  try {
    const response: AxiosResponse = await axios.get(`${apiUrl}/case/${caseId}`);
    return response.data as LlmCase;
  } catch (error) {
    console.error("Error trying to get llm-case:", error);
    throw new ce.CaseRetrievalError("Could not retrieve case. Please try again later.");
  }
}

export interface IDocstoreData {
  [key: string]: {
    __data__: JSON;
  };
}
export async function getIndexData(indexId: string): Promise<IDocstoreData> {
  try {
    const response: AxiosResponse = await axios.get(`${llmUrl}/v1/index/${indexId}`);
    return response.data["docstore/data"];
  } catch (error) {
    console.error("Error trying to get index:", error);
    throw new ce.IndexRetrievalError("Could not retrieve index. Please try again later.");
  }
}

export function getUniqueIndexIds(messages: LlmCaseMessage[]): string[] {
  let uniqueIndexIds = Array.from(new Set(messages.map((message) => message.index_id)));
  uniqueIndexIds = uniqueIndexIds.filter((indexId) => indexId !== "");
  return uniqueIndexIds;
}

export async function getLlmModels(): Promise<string[]> {
  try {
    const response: AxiosResponse = await axios.get(`${llmUrl}/v1/models`);
    return response.data["models"] as string[];
  } catch (error) {
    console.error("Error trying to get llm-models:", error);
    throw new ce.LlmModelRetrievalError("Could not retrieve models. Please try again later.");
  }
}

export interface LLMWorkflow {
  id: string;
  title: string;
  owner: string;
  tenant: string;
  prompts: string;
  created: number;
  last_modified: number;
}
export async function getLlmWorkflows(): Promise<LLMWorkflow[]> {
  try {
    const response: AxiosResponse = await axios.get(`${apiUrl}/llm/workflow`, {
      timeout: 15000,
    });
    return response.data as LLMWorkflow[];
  } catch (error) {
    if (error.code === "ECONNABORTED") {
      console.error("Timeout trying to get workflows");
    } else {
      console.error("Error trying to get workflows:", error);
    }
    return Promise.reject(error);
  }
}

export type ServerType = "premium" | "standard" | "hetzner";
export interface LLMPlaybook {
  id: string;
  title: string;
  owner: string;
  tenant: string;
  index_location: ServerType;
  creation_date: number;
  documents: JSON[]; // TODO: define document type
}
export async function getLlmPlaybooks(): Promise<LLMPlaybook[]> {
  try {
    const response: AxiosResponse = await axios.get(`${apiUrl}/llm/playbook`, {
      timeout: 15000,
    });
    return response.data as LLMPlaybook[];
  } catch (error) {
    if (error.code === "ECONNABORTED") {
      console.error("Timeout trying to get playbooks");
    } else {
      console.error("Error trying to get playbooks:", error);
    }
    return Promise.reject(error);
  }
}

export interface RAGResponse {
  sources: RAGSource[];
  response: string[];
}
export interface RAGSource {
  name: string;
  text: string;
  score: number;
}
export type LLMStreamEventCategory = "chat" | "document" | "playbook" | "followup" | "summary";
export type LLMStreamEventRole = "user" | "assistant";
export type LLMStreamEventType =
  | "user_chat"
  | "assistant_chat"
  | "user_document"
  | "assistant_document"
  | "user_playbook"
  | "assistant_playbook"
  | "user_followup"
  | "assistant_followup"
  | "sources"
  | "assistant_status"
  | "user_summary"
  | "assistant_summary"
  | "heartbeat";
export function isUserRole(type: LLMStreamEventType | string): boolean {
  if (type.startsWith("user_")) {
    return true;
  } else {
    return false;
  }
}
export const LLMStreamEventLabel: { [key in LLMStreamEventType]: string } = {
  user_chat: "F", //"Q",
  assistant_chat: "A",
  user_document: "F", //"Q",
  assistant_document: "A",
  user_playbook: "Abgleich mit Spielbuch Aufforderung", //"Abgleich mit Playbook Prompt",
  assistant_playbook: "Abgleich mit Spielbuch", //"Abgleich mit Playbook",
  user_followup: "Aufforderung Fortsetzung", //"Follow-up Prompt",
  assistant_followup: "Antwort Fortsetzung", //" Follow-up Antwort",
  sources: "",
  assistant_status: "Status",
  user_summary: "F", //"Q",
  assistant_summary: "Kurze Zusammenfassung",
  heartbeat: "",
};
export const LLMMessageTypeLabels: { [key: string]: string } = {
  chat: "Chat",
  document: "Chat mit Dokument",
  playbook: "Playbook Abgleich",
  followup: "Followup",
  status: "Status",
  summary: "Zusammenfassung",
};
export const getLLMMessageTypeLabel = (messageType: string) => {
  const matchingLabel = LLMMessageTypeLabels[messageType];
  if (matchingLabel) {
    return matchingLabel;
  }
  return "N/A";
};
export type LLMStreamType =
  | "chat/completions"
  | "rag"
  | "workflow"
  | "workflow/remote"
  | "workflow/legacy"
  | "rag/summary";
export interface IMessageData extends IMessageBase {
  type: LLMStreamEventType;
}
export interface IMessageDataPlus extends IMessageData {
  category: LLMStreamEventCategory;
  role: LLMStreamEventRole;
}

/**
 * This function starts a long-lived-model stream.
 *
 * @param server The server to connect to
 * @param params The parameters for the stream (body)
 * @param streamType The type of the stream
 * @param cancelStream A function to cancel the stream
 * @param onQuestion A function to handle questions
 * @param onAnswer A function to handle answers
 * @param onDocumentQuestion A function to handle document questions
 * @param onDocumentAnswer A function to handle document answers
 * @param onFollowupQuestion A function to handle followup questions
 * @param onFollowupAnswer A function to handle followup answers
 * @param onPlaybookQuestion A function to handle playbook questions
 * @param onPlaybookAnswer A function to handle playbook answers
 * @param onSummaryQuestion A function to handle summary questions
 * @param onSummaryAnswer A function to handle summary answers
 * @param onStatus A function to handle status messages
 * @param onAppend A function to handle append messages
 * @param onStart A function to handle the start of the stream
 * @param onError A function to handle errors
 * @param onClose A function to handle the closing of the stream
 * @param onSources A function to handle sources
 *
 * @returns {Promise<void>} A promise that resolves when the stream is started
 */
export const startLLMStream = async ({
  params,
  streamType,
  cancelStream = () => {},
  onQuestion = () => {},
  onAnswer = () => {},
  onDocumentQuestion = () => {},
  onDocumentAnswer = () => {},
  onFollowupQuestion = () => {},
  onFollowupAnswer = () => {},
  onPlaybookQuestion = () => {},
  onPlaybookAnswer = () => {},
  onStatus = () => {},
  onSummaryQuestion = () => {},
  onSummaryAnswer = () => {},
  onAppend = () => {},
  onStart = () => {},
  onError = () => {},
  onClose = () => {},
  onSources = () => {},
}: {
  params: any;
  streamType: LLMStreamType;
  cancelStream?: () => void;
  onQuestion?: (data: IMessageDataPlus) => void;
  onAnswer?: (data: IMessageDataPlus) => void;
  onFollowupQuestion?: (data: IMessageDataPlus) => void;
  onFollowupAnswer?: (data: IMessageDataPlus) => void;
  onDocumentQuestion?: (data: IMessageDataPlus) => void;
  onDocumentAnswer?: (data: IMessageDataPlus) => void;
  onPlaybookQuestion?: (data: IMessageDataPlus) => void;
  onPlaybookAnswer?: (data: IMessageDataPlus) => void;
  onSummaryQuestion?: (data: IMessageDataPlus) => void;
  onSummaryAnswer?: (data: IMessageDataPlus) => void;
  onStatus?: (value: string) => void;
  onAppend?: (value: string) => void;
  onStart?: () => void;
  onError?: () => void;
  onClose?: () => void;
  onSources?: (value: IMessageData) => void;
}): Promise<void> => {
  // TODO: timeout ?

  await fetchEventSource(`${llmUrl}/v1/${streamType}`, {
    method: "POST",
    openWhenHidden: true,
    body: JSON.stringify(params),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      Accept: "text/event-stream",
    },
    onopen: async (res: Response): Promise<void> => {
      if (res.ok && res.status === 200) {
        onStart();
      } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
        console.error("Client-Side Errror  opening LLM Stream", res);
        onError();
      }
    },
    onmessage(event: { data: string }) {
      if (!event.data || event.data.length == 0) {
        return;
      }
      const data: IMessageData = JSON.parse(event.data);

      switch (data.type) {
        case "user_chat":
          onQuestion({ ...data, category: "chat", role: "user" });
          break;
        case "assistant_chat":
          onAnswer({ ...data, category: "chat", role: "assistant" });
          break;
        case "user_document":
          onDocumentQuestion({ ...data, category: "document", role: "user" });
          break;
        case "assistant_document":
          onDocumentAnswer({ ...data, category: "document", role: "assistant" });
          break;
        case "user_followup":
          onFollowupQuestion({ ...data, category: "followup", role: "user" });
          break;
        case "assistant_followup":
          onFollowupAnswer({ ...data, category: "followup", role: "assistant" });
          break;
        case "user_playbook":
          onPlaybookQuestion({ ...data, category: "playbook", role: "user" });
          break;
        case "assistant_playbook":
          onPlaybookAnswer({ ...data, category: "playbook", role: "assistant" });
          break;
        case "user_summary":
          onSummaryQuestion({ ...data, category: "summary", role: "user" });
          break;
        case "assistant_summary":
          onSummaryAnswer({ ...data, category: "summary", role: "assistant" });
          break;

        case "assistant_status":
          onStatus(data.content);
          break;

        case "sources":
          onSources(data);
          break;
        case "heartbeat":
          break;
        default:
          onAppend(data.content);
          break;
      }
    },
    onclose() {
      onClose();
    },
    onerror(err) {
      console.log("On error", err);
      onError();
      throw new Error();
    },
  });
};
